const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="site-footer-inner has-top-divider">
          <div className="footer-copyright">
            &copy; 2020 Conwo, all rights reserved
          </div>
          <div className="footer-social-links">
            <div>
              <a href="https://g.page/Conwo?share">
                <span className="screen-reader-text">Find Us!</span>
                <svg
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <defs>
                    <clipPath id="A">
                      <rect width="512" height="512" rx="15%" />
                    </clipPath>
                  </defs>
                  <g clip-path="url(#A)">
                    <path fill="#35a85b" d="M0 0h512v512H0z" />
                    <path fill="#fadb2a" d="M-50 512L512-50l32 600z" />
                    <path fill="#fff" d="M32 512L512 32v480z" />
                    <path fill="#5881ca" d="M256 288L32 512h448z" />
                    <path fill="#c1c0be" d="M288 256L512 32v448z" />
                    <path
                      d="M149 113c23-11 51-6 70 11l-18 17c-18-18-53-10-62 15-11 24 8 55 34 56 18 2 35-10 39-27h-37v-23c21 1 42-1 62 1 1 15 0 31-8 44-12 23-42 33-67 27-31-6-55-38-50-69 2-23 17-43 37-52"
                      fill="#f2f2f2"
                    />
                  </g>
                  <path
                    d="M418 66c-10 2-18 11-19 20-1 10 4 19 13 23 8 5 21 4 28-3 7-6 10-18 6-26-4-11-17-17-28-14zm1-45h8c20 1 40 12 53 28 8 10 12 23 13 36v5c0 25-16 45-30 66-16 21-30 45-35 73-1 5 1 11-5 13l-4-3c-1-15-5-30-11-44-8-16-18-32-29-46-6-9-12-17-17-27a67 67 0 0 1 10-81c13-11 30-19 47-20"
                    fill="#de3738"
                  />
                  <circle cx="423" cy="89" r="25" fill="#7d2426" />
                </svg>
              </a>
            </div>
            <div>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div>
              <a href="mailto:contact@conwo.co">contact@conwo.co</a>
            </div>
            <div>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div>
              <a href="tel:080-26634178">080-26634178</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
