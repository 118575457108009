import { useState } from "react";
import Modal from "react-modal";
import emailjs from "emailjs-com";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

type FormElementParams = {
  name: string;
  type: string;
  placeholder: string;
};

const InputFormElement = (props: FormElementParams) => {
  return (
    <input
      className="input mb-8"
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
    />
  );
};

const sendEmail = (e: any, closeModal: any) => {
  e.preventDefault();
  const target = {
    company: e.target[0].value,
    name: e.target[1].value,
    email: e.target[2].value,
    phone_number: e.target[3].value,
    message: e.target[4].value,
  };

  emailjs.send(
    "service_5qravbs",
    "template_xeswv3e",
    target,
    "user_qfT5Z9LoYOnVHoIL97CkC"
  );

  if (closeModal) {
    closeModal();
  }
};

const ContactUs = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="newsletter section text-light">
      <div className="container-sm">
        <div className="newsletter-inner section-inner">
          <div className="newsletter-header text-center">
            <h2 className="section-title mt-0">Stay in the know</h2>
            <p className="section-paragraph">
              We're in the process of adding more information in the future.
              Keep checking to know more!!
              <br />
              <br />
              Want to join us?
              <a
                href="https://angel.co/company/conwo/jobs/"
                target="_blank"
                rel="noreferrer"
              >
                <b> Careers@Conwo</b>
              </a>
            </p>
            <div className="justify-children-center">
              <button
                className="button button-primary button-block"
                onClick={openModal}
              >
                Contact Us
              </button>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="footer-form newsletter-form field field-grouped">
                <div className="control control-expanded">
                  <form
                    id="contactus"
                    onSubmit={(e) => sendEmail(e, closeModal)}
                  >
                    <InputFormElement
                      type="text"
                      name="company"
                      placeholder="Name of your company"
                    />
                    <InputFormElement
                      type="text"
                      name="name"
                      placeholder="Name"
                    />
                    <InputFormElement
                      type="email"
                      name="email"
                      placeholder="Email Address"
                    />
                    <InputFormElement
                      type="text"
                      name="phone_number"
                      placeholder="Phone Number"
                    />
                    <textarea
                      className="textarea mb-8"
                      name="message"
                      placeholder="Message"
                      rows={5}
                    />
                  </form>
                  <div className="control">
                    <button
                      className="button button-primary button-block button-shadow"
                      //onClick={sendEmail}
                      type="submit"
                      form="contactus"
                    >
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
