import { useEffect } from "react";

import "assets/scss/style.scss";

import HeroSection from "components/HeroSection";
import Header from "components/Header";
import FeaturesSection from "components/FeaturesSection";
import AboutUs from "components/AboutUs";
import ContactUs from "components/ContactUs";
import Footer from "components/Footer";

import { applyAnimation } from "animations";

function App() {
  useEffect(() => {
    //The default HTML page was using JS to trigger animation post load. Trigger the same now wit useEffect
    applyAnimation();
  });

  return (
    <div className="App">
      <Body />
    </div>
  );
}

var Body = () => {
  return (
    <body>
      <div className="body-wrap boxed-container">
        <main>
          <Header />
          <HeroSection />
          <FeaturesSection />
          <AboutUs />
          <ContactUs />
        </main>
        <Footer />
      </div>
    </body>
  );
};

export default App;
