import { CSSProperties } from "react";

const textAlignJustifyStyle: CSSProperties = {
  textAlign: "justify",
};

const AboutUs = () => {
  return (
    <section className="testimonials section">
      <div className="container">
        <div>
          <h2>About us</h2>
        </div>
        <div>
          <p className="text-sm" style={textAlignJustifyStyle}>
            At Conwo, we want to make digitization simpler by providing an end
            to end solution, enabling companies of all sizes to get the benefits
            of IoT, without the need to change their existing processes or
            assets - this could be machines on manufacturing shop floors or
            vehicles in logistics or passenger fleets.
            <br />
            <br />
            Our portfolio of complete Internet of Things ("loT") solutions
            combine hardware, software, and cloud to bring real-time visibility,
            analytics, and Al to operations. This technology, can be used to
            increase the efficiency, safety, and sustainability of the
            operations.
            <br />
            <br />
            Our current team comprises of Microsoft and Intuit alums who have
            graduated from premier institutes like RVCE, IIM-Ahmedabad and
            PESIT.
          </p>
        </div>
        <br />
      </div>
    </section>
  );
};

export default AboutUs;
